@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

.mobile-container {
    width: 100%;
    height: 100%;
    padding: 8px 12px;
    background: rgba(255, 255, 255, 0.80);
    box-shadow: 0px 2px 6.3px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    border: 1px #E4E4E4 solid;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex;
    margin-bottom: 10px;
  }
  
  .inner-container {
    align-self: stretch;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }
  
  .inner-box {
    padding: 4px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
  }
  
  .number-box {
    text-align: center;
    color: #141414;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 600;
    line-height: 16px;
    word-wrap: break-word;
  }
  
  .badge {
    padding: 2px 4px;
    background: #D8D8D8;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .badge-text {
    color: #141414;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 600;
    line-height: 16px;
    word-wrap: break-word;
  }
  
  .m-content {
    flex: 1 1 0;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }
  
  .text-content {
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex;
  }
  
  .names {
    width: 224.5px;
    color: #141414;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 600;
    line-height: 16px;
    word-wrap: break-word;
  }
  
  .guest-info {
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex;
  }
  
  .guest-badge {
    padding: 4px 8px;
    
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
  }
  
  .guest-text {
    color: #141414;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 600;
    text-transform: uppercase;
    line-height: 14px;
    word-wrap: break-word;
  }
  
  .car-info {
    flex: 1 1 0;
    color: #141414;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 16px;
    word-wrap: break-word;
  }
  
  .timing {
    align-self: stretch;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    display: inline-flex;
  }
  
  .time {
    text-align: right;
    color: #141414;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 600;
    line-height: 16px;
    word-wrap: break-word;
  }
  
  .penalty {
    padding: 2px 8px;
    background: rgba(247, 129, 104, 0.50);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .penalty-text {
    color: #620505;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 600;
    text-transform: uppercase;
    line-height: 14px;
    word-wrap: break-word;
  }
  
  .additional-times {
    height: 32px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    display: flex;
  }
  
  .additional-time {
    align-self: stretch;
    text-align: right;
    color: #141414;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 16px;
    word-wrap: break-word;
  }
  