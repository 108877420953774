@import '../src/bulmaswatch.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-divider {
  border-left: 2px solid #ddd; /* Customize the divider style as needed */
  margin-top: 20px; /* Adjust the margin to match your design */
  margin-bottom: 20px; /* Adjust the margin to match your design */
}

.tables-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 50%;
}

.table-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: calc(50% - 20px); /* 50% width with 10px margin on each side */
}



 .driver-info, .car-info {
  white-space: pre-line;
 /*  display: flex;
  flex-direction: column;
  align-items: flex-start;*/
}

.position-box, .car-number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width:2.5em;
  height:2.5em;
  
  color: white;
  font-weight: bold;
  border-radius: 1ch;
  margin: auto; /* Add this line to center the position-box content */
}
.car-number-box {
  background-color: #575757;
}
.position-box {
  background-color: #1f1f1f;
}


th.center-text {
  text-align: center;
  vertical-align: middle;
  
}
.table th.pos-column,
.table td.pos-column,
.table th.car-column,
.table td.car-column {
  width: auto;
  text-align: center;
}
/* Custom Carousel Styles */
.splide {
 
  margin: 0 auto; /* Center the slider on the page */
  padding: 20px; /* Add padding around the slider */
 
  border-radius: 8px; /* Add border-radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
}

.splide__slide {
  height: 50px; /* Set the height of each slide */
  margin-right: 10px; /* Add margin between slides */
}

.splide__slide:last-child {
  margin-right: 0; /* Remove margin for the last slide */
}

.splide__slide img {
  width: 100%; /* Make the images fill the entire slide */
  height: 100%; /* Make the images fill the entire slide height */
  border-radius: 8px; /* Add border-radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  object-fit: contain;
}
.co-card {
  height: 100%; /* Set a fixed height for the card */
  display: flex;
  flex-direction: column;
  border-radius: 8px; /* Add border-radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

.co-card .card-image {
  flex: 1; /* Take up remaining space, adjusting the image height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.co-card .card-image img {
  object-fit: contain; /* Maintain aspect ratio and fit within the container */
  max-height: 100%; /* Set a maximum height for the image */
  max-width: 100%; /* Set a maximum width for the image */
  width: auto; /* Let the browser calculate the width */
}

.co-card-content {
  height: 40%; /* Adjust the content height based on your preference */
  padding: 1rem; /* Add padding to the content */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.custom-card .card-image {
  flex: 1; /* Take up remaining space, adjusting the image height */
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-card-buttons {
  display: flex;
  align-items: center;
}

.custom-card-buttons .button {
  margin-right: 10px; /* Add some spacing between buttons if needed */
}

.custom-card-buttons .button:last-child {
  margin-right: 0; /* Remove right margin for the last button */
}
.competition-card{
  border-radius: 8px; /* Add border-radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);


}
.mt-1{
margin-top: 1rem;


}
.timeDiff-info{
  white-space: pre-line;
}
.is-selected-step {
  font-weight: 700;
}
@keyframes rotate {
  to {
      transform: rotate(360deg);
  }
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Adjust the height as needed */
}

.loading-spinner::after {
  content: '';
  width: 20px; /* Adjust the size of the arrow */
  height: 20px;
  border: 4px solid #333; /* Adjust the color */
  border-right-color: transparent;
  border-radius: 50%;
  animation: rotate 1s infinite linear;
}
.result-table{
  /*  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
} 

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.is-pulsating {
  animation: pulse 1s infinite;
}


.selected {
  background-color: #b3d9ff; /* You can customize the background color */
  color: #000; /* You can customize the text color */
  /* Add any other styles you want for the selected row */
}

/* Optional: Add a transition effect for a smoother hover and click experience */
.selected:hover {
  background-color: #a1c8f1; /* You can customize the hover background color */
}

.is-no-active{
  background-color: white;
  border: 0.1rem solid rgb(49, 49, 49);
}

.is-normal-poin{
  background-color: white;
  border: 0.1rem solid rgb(49, 49, 49);


}
.select-width-consistent {
  width: 100%; /* or any specific width you prefer */
}
/* Adjusting for a transparent background */
.scroll-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px; /* Positioned on the right side */
  z-index: 1000;
  background-color: transparent; /* Making the background transparent */
  color: #ff5f5f; /* Example icon color, adjust as needed */
  
  cursor: pointer;
  padding: 10px;
  border: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Optional: adding a shadow for better visibility */
}

/* Adjustments for hover state for better interaction feedback */
.scroll-to-top-btn:hover {
  background-color: rgba(160, 160, 160, 0.1); /* Slightly colored on hover for feedback */
   /* Darker border on hover */
}
.container-bkg{
  background: linear-gradient(#fff 27.25%, #fff5c3 115.88%);
}

