// Override Bulma colors for completed steps and active states
$steps-color: #f9c74f; // Yellow for all steps
$steps-completed-color: #f9c74f; // Yellow for completed steps
$steps-completed-background-color: #f9c74f; // Yellow background for completed steps


@import '../node_modules/bulma-o-steps/bulma-steps.min.css';

// Import Bulma and bulma-o-steps after variable overrides
// Override color for completed steps
.steps .steps-segment.is-completed .steps-marker {
    background-color: #f9c74f !important; // Yellow for completed steps markers
    border-color: #f9c74f !important;
}

// Override color for line connecting completed steps
.steps .steps-segment.is-completed + .steps-segment::before {
    background-color: #f9c74f !important; // Yellow line for completed steps
    border-color: #f9c74f !important;
}

// Also target the line to force color across different browsers
.steps .steps-segment:not(.is-active)::before {
    background-color: #ffe195 !important; // Yellow for completed line
}
.steps-segment:after {
    background-color: #ffe195;
}