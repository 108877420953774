/* Import Inter font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

.desk-table-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}

.title {
  color: #141414;
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 40px;
  word-wrap: break-word;
}

.dskt-table-container {
  align-self: stretch;
  height: 536px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.table-header th {
  padding: 16px;
  background: white;
  border-bottom: 3px solid #E4E4E4;
  color: #969696;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  word-wrap: break-word;
  text-align: center; /* Center text in header cells */
}

.table-row td {
  padding: 16px;
 
  color: #141414;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  line-height: 16px;
  word-wrap: break-word;
  text-align: center; /* Center text in table cells */
  vertical-align: middle; /* Center content vertically */
}

.table-cell-number,
.table-cell-position {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-cell-number {
  width: 10px;
  font-weight: 600;
}

.table-cell-position {
  width: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.position-badge {
  padding: 4px 8px;
  margin: 8px;
  background: #D8D8D8;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.position-badge-text {
  color: #141414;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 16px;
}

.table-cell-name,
.table-cell-car {
  text-align: left; /* Left align text for specific columns */
}

.table-cell-car {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
}

.guest-badge {
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guest-badge-text {
  color: #141414;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 14px;
}

.table-cell-time {
  width: 144px;
  font-weight: 600;
}

.table-cell-difference {
  width: 88px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.penalty {
    padding: 2px 8px;
    background: rgba(247, 129, 104, 0.50);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
  }
  
  .penalty-text {
    color: #620505;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 600;
    text-transform: uppercase;
    line-height: 14px;
    word-wrap: break-word;
  }
  .dskt-table-container {
    width: 100%;
    height: 100%;
    padding: 24px;
    background: rgba(255, 255, 255, 0.80);
    box-shadow: 0px 4px 20.7px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    border: 2px solid #E4E4E4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 24px;
    margin-top: 25px;
    align-content: space-around;
}
.container-header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .header {
    width: 1920px;
    height: 133px;
    padding: 0px 32px;
    border-bottom: 1px solid #E4E4E4;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
.competition-section {
    display: flex;
    align-items: flex-end;
    gap: 24px;
  }
  
  .competition-logo {
    width: 322px;
    height: 120px;
  }
  
  .logo-image {
    max-width: 100%;
    height: auto;
  }
  
  .competition-buttons {
    display: flex;
    align-items: flex-start;
    margin-right: 100px;
  }
  
  .competition-button {
    padding: 12px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    
  }
  
  .competition-button.selected {
    background: rgba(255, 225.25, 148.75, 0.24);
    border-bottom: 3px solid #FFE195;
  }
  
  .competition-name {
    color: #141414;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 16px;
    word-wrap: break-word;
  }
  
  .competition-button.selected .competition-name {
    font-weight: 600;
  }
  
  .classification-buttons {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }
  
  .classification-button {
    padding: 12px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  
  .classification-button.selected {
    background: rgba(255, 225.25, 148.75, 0.24);
    border-bottom: 3px solid #FFE195;
  }
  
  .classification-name {
    color: #141414;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 16px;
    word-wrap: break-word;
  }
  
  .classification-button.selected .classification-name {
    font-weight: 600;
  }
  
  .content {
    align-self: stretch;
    padding: 0px 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }
  
  .location-info {
   
    height: 56px;
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.location-name,
.location-distance {
    color: #141414;
    font-size: 26px; /* Default font size */
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    line-height: 40px;
    white-space: nowrap;        /* Prevent wrapping */
    overflow: hidden;           /* Hide overflowing content */
    text-overflow: ellipsis;    /* Add ellipsis for overflowing text */
    word-wrap: normal;          /* Avoid breaking words */
    font-size: clamp(16px, 2vw, 26px); /* Adjust font size dynamically to fit */
}
  
 
  .content li {
    margin-top: .25em;
}
.steps-container {
    flex: 1;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
 
.steps .steps-segment .steps-content .step-label {
    padding: 8px 16px;
    border-radius: 8px;
    border: 2px solid #C6C6C6;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #141414;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 16px;
    word-wrap: break-word;
    margin-top:10px;
    cursor: pointer;
  }
  
  .steps .steps-segment.is-active .steps-content .step-label {
    background: rgba(255, 225.25, 148.75, 0.24);
    border-color: #FFE195;
    cursor: pointer;
  }
  .steps li {
    list-style-type: none; /* Remove dots from list items */
   
  }
  .steps:not(.is-hollow) .steps-segment.is-active .steps-marker:not(.is-hollow) {
    background-color: #FFE5A2;
    color: #fff;
    cursor: pointer;
}

/* Container for the entire block */
.custom-container {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  display: inline-flex;
}

/* Column container */
.custom-column {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
}

/* Search box */
.custom-search-box {
  width: 300px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 2px solid #E4E4E4;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

/* Search text */
.custom-search-text {
  color: #969696;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;
}

/* Container for the entire dropdown */
.custom-class-selection {
  position: relative;
  width: 200px; /* Adjust based on your design */
  border-radius: 8px;
  border: 2px solid #ffe195;
  background-color: rgba(255, 225, 148, 0.24);
  overflow: hidden; /* Ensure no content overflows the boundaries */
  padding: 0; /* Remove extra padding to allow select to occupy full area */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Indicate it's clickable */
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Custom select dropdown */
.custom-select {
  width: 100%; /* Ensures the dropdown is the same width as the container */
  height: 100%; /* Ensures the dropdown is the same height as the container */
  border: none; /* Remove default border */
  background-color: transparent; /* Make the background transparent */
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  appearance: none; /* Remove default dropdown arrow */
  outline: none; /* Remove default focus outline */
  cursor: pointer;
  box-sizing: border-box;
  padding: 12px 24px; /* Adjusted padding to fit the container */
}

/* Custom arrow */
.custom-class-selection::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #141414;
  font-size: 12px; /* Adjust size to fit within the design */
}

/* Dropdown menu */
.custom-select option {
  background-color: #ffffff;
  color: #141414;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  width: 100%; /* Ensures options are the full width of the select box */
  box-sizing: border-box; /* Ensures padding is included in width calculation */
}
tr.table-row {
  border-bottom: 1px solid #E4E4E4;
}

.taryfa {
  padding: 2px 8px;
  background: rgba(255, 198, 92, 0.5);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.taryfa-text {
  color: #3d3d3d;
  font-size: 12px;
  font-family: 'Inter';
  font-weight: 600;
  text-transform: uppercase;
  line-height: 14px;
  word-wrap: break-word;
}